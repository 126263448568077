.app-bar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo-title {
  height: 60px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
}

.logo-title-info {
  height: 60px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}

.algo-logo {
  height: 80%;
  margin-right: 10px;
}

@media (max-width: 350px) {
  .algo-logo {
    margin-left: 10px;
  }
}

.toolbar-slider {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}

.toolbar-speed {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}

.toolbar-toggle-barriers {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}

.toolbar-button {
  outline: none;
  border: none;
  background: none;
  cursor: pointer;
  transition: transform 0.1s ease-out;
}

.refresh-algorithm:active {
  transform: scale(0.9);
}

.light-switch {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  outline: none;
  background: none;
  border: none;
  cursor: pointer;
  transition: transform 0.1s ease-out;
}

.light-switch:active {
  transform: scale(0.9);
}

.burger-button {
  cursor: pointer;
}

@media (max-width: 1150px) {
  .toolbar-speed {
    display: none;
  }

  .left-click-state {
    display: none;
  }

  .toolbar-slider {
    display: none;
  }

  .light-switch {
    display: none;
  }
}

@media (min-width: 1151px) {
  .burger-button {
    display: none;
  }
}
